<template>
  <v-layout wrap>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedEmployee == null && employees.length > 0">
      <v-list>
        <v-list-tile
          v-for="employee in employees"
          :key="employee.id"
          avatar
          @click="selectedEmployee = employee">
          <v-list-tile-content>
            <v-list-tile-title v-text="getEmployeeName(employee)"></v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-avatar>
            <v-icon>account_circle</v-icon>
          </v-list-tile-avatar>
        </v-list-tile>
      </v-list>
    </v-flex>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedEmployee == null">
      <v-layout justify-end>
        <v-btn @click="addEmployee" color="success" small dark>
          nieuwe medewerker
          <v-icon right dark>add</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <employeeForm 
      v-if="selectedEmployee != null" 
      :employee="selectedEmployee" 
      v-on:done="selectedEmployee = null"
      v-on:reset="getEmployees">
    </employeeForm>
  </v-layout>
</template>

<script>
import employeeForm from '../../components/EmployeeForm.vue'
export default {
  name: 'Employee',
  components: { 
    employeeForm
  },
  data () {
    return {
      selectedEmployee: null,
      employees: []
    }
  },
  methods: {
    getEmployees () {
      this.selectedEmployee = null
      this.$http.get('api/employees/')
        .then(request => this.employees = request.data)
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    getEmployeeName: function(employee) {
      return ((employee.firstname || '') + ' ' + (employee.surnamePrefix || '') + ' ' +  (employee.surname || '')).replace('  ', ' ')
    },
    addEmployee () {
      this.employees.push({
        firstname: ''
      })
      this.selectedEmployee = this.employees[this.employees.length-1]
    }
  },
  mounted () {
    this.getEmployees()
  }
}
</script>