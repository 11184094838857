<template>
  <v-form>
    <v-container fluid grid-list>
      <v-layout wrap>
        <v-flex xs12>
          <v-text-field
            v-model="employee.firstname"
            :error-messages="firstnameErrors"
            :counter="100"
            label="Voornaam"
            required
            @input="$v.employee.firstname.$touch()"
            @blur="$v.employee.firstname.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 md4>
          <v-text-field
            v-model="employee.surnamePrefix"
            :counter="10"
            label="Tussenvoegsel"
            @input="$v.employee.surnamePrefix.$touch()"
            @blur="$v.employee.surnamePrefix.$touch()"
          ></v-text-field>  
        </v-flex>
        <v-flex xs12 md8>
          <v-text-field
            v-model="employee.surname"
            :error-messages="surnameErrors"
            :counter="100"
            label="Achternaam"
            required
            @input="$v.employee.surname.$touch()"
            @blur="$v.employee.surname.$touch()"
          ></v-text-field>      
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-text-field
            v-model="employee.email"
            :error-messages="emailErrors"
            :counter="100"
            label="E-mail"
            required
            @input="$v.employee.email.$touch()"
            @blur="$v.employee.email.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 md4>
          <v-select
            v-model="employee.sex" 
            :items="sexes"
            item-text="name"
            item-value="id"
            required
            label="Geslacht"
          ></v-select>
        </v-flex>
        <v-flex xs6 md4>
          <v-text-field
            v-model="employee.workHours"
            :error-messages="workHoursErrors"
            mask="##"
            label="Contracturen"
            required
            @input="$v.employee.workHours.$touch()"
            @blur="$v.employee.workHours.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 md4>
          <v-text-field
            v-model="employee.leaveHoursPerYear"
            :error-messages="leaveHoursPerYearErrors"
            mask="###.#"
            return-masked-value
            label="Verlofuren per jaar"
            required
            @input="$v.employee.leaveHoursPerYear.$touch()"
            @blur="$v.employee.leaveHoursPerYear.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 md4>
          <v-switch
            :label="`Urenregistratie`"
            v-model="employee.hasTimesheet"
          ></v-switch>
        </v-flex>
        <v-flex>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-btn color="primary" @click="submit">opslaan</v-btn>
          <v-btn color="primary" @click="back">annuleren</v-btn>
          <span>{{submitStatus}}</span>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
  import { required, maxLength, email, numeric, minValue, maxValue } from 'vuelidate/lib/validators'

  export default {
    props: ['employee'],
    mixins: [validationMixin],
    validations: {
      employee: {
        firstname: { required, maxLength: maxLength(100) },
        surnamePrefix: { maxLength: maxLength(10) },
        surname: { required, maxLength: maxLength(100) },
        email: { required, email, maxLength: maxLength(100) },
        workHours: { required, numeric, minValue: minValue(0), maxValue: maxValue(40) },
        leaveHoursPerYear: { required, minValue: minValue(0) }
      }
    },

    data: () => ({
      submitStatus: null,
      sexes: [
        {id: 0, name: 'Man'}, 
        {id: 1, name: 'Vrouw'}
        ]
    }),

    computed: {
      firstnameErrors () {
        const errors = []
        if (!this.$v.employee.$dirty) return errors
        !this.$v.employee.firstname.maxLength && errors.push('Voornaam kan maximaal 100 tekens bevatten')
        !this.$v.employee.firstname.required && errors.push('Dit veld is verplicht')
        return errors
      },
      surnameErrors () {
        const errors = []
        if (!this.$v.employee.$dirty) return errors
        !this.$v.employee.surname.maxLength && errors.push('Achternaam kan maximaal 100 tekens bevatten')
        !this.$v.employee.surname.required && errors.push('Dit veld is verplicht')
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.employee.$dirty) return errors
        !this.$v.employee.email.maxLength && errors.push('Moet een geldig e-mail adres zijn')
        !this.$v.employee.email.required && errors.push('Dit veld is verplicht')
        return errors
      },
      workHoursErrors () {
        const errors = []
        if (!this.$v.employee.$dirty) return errors
        !this.$v.employee.workHours.maxValue && errors.push('Contracturen kan niet meer dan 40 zijn')
        !this.$v.employee.workHours.minValue && errors.push('Contracturen kan niet minder dan 0 zijn')
        !this.$v.employee.workHours.required && errors.push('Dit veld is verplicht')
        return errors
      },
      leaveHoursPerYearErrors () {
        const errors = []
        if (!this.$v.employee.$dirty) return errors
        !this.$v.employee.email.minValue && errors.push('Verlofuren kan niet minder dan 0 zijn')
        !this.$v.employee.leaveHoursPerYear.required && errors.push('Dit veld is verplicht')
        return errors
      }
    },

    methods: {
      submit() {
        this.$v.employee.$touch()
        if (this.$v.employee.$invalid) {
          this.submitStatus = 'ERROR1'
        } else {
          if (this.employee.id == undefined) {
            this.submitStatus = 'POSTING'
            this.$http.post('api/employees/', this.employee)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR2'
                }
              })
              .catch(() => { 
                console.error('put error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR3'
              })
          } else {
            this.submitStatus = 'PUTTING'
            this.$http.put('api/employees/' + this.employee.id, this.employee)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR2'
                }
              })
              .catch(() => { 
                console.error('put error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR3'
              })
          }
        }
      },
      back () {
        this.$v.$reset()
        this.$emit('reset')
      },
      save() {     
      }
    }
  }
</script>